// DO NOT CHANGE - it was agreed with the BE team that the order of OccupantType should be like this
export enum OccupantType {
    Occupant = 0,
    Amenity = 1,
    Carousel = 2,
    Gate = 3
}

export interface OpeningHours {
    closedAllDay: boolean,
    startTime: string,
    endTime: string
}

export interface Field {
    text: string,
    type: string,
    value: string
}