<div fxFlexFill fxLayout="column" class="page-content">
    <mat-progress-bar mode="indeterminate" color="accent" *ngIf="isProgressbarVisible"></mat-progress-bar>
    <div class="page-content__overlay" *ngIf="showSpinner">
        <mi-spinner inverse></mi-spinner>
    </div>
    <div class="grid-content">
        <mi-card *ngFor="let dataOpt of dataOptions" DragDrop (filesChangeEmiter)="chooseFile($event, dataOpt)"
                 [allowed_extensions]="['csv']">
            <div class="card__row">
                <h1>{{dataOpt.title}}</h1>
                <button type="button"
                        *ngIf="dataOpt.fileType === 'csv'"
                        class="mi-button mi-button--base mi-button--icon-left mi-button--file-upload mi-button--small card__action"
                        [disabled]="dataOpt.showSpinner"
                        (click)="fileInput.click()">
                    <object data="/assets/icons/cloud_upload.svg" type="image/svg+xml"></object>
                    Upload CSV
                    <input type="file" accept=".csv" #fileInput [id]="dataOpt.path+'-input'"
                           (change)="chooseFile($event, dataOpt)">
                </button>
            </div>
            <hr class="card__horizontal-ruler" />
            <div class="card__row card__row--reverse">
                <button type="button"
                        class="mi-button mi-button--primary mi-button--icon-left  mi-button--default card__action"
                        [disabled]="showSpinner || (dataOpt.selectedVenues && dataOpt?.selectedVenues.length === 0)"
                        (click)="download(dataOpt)">
                    <object data="/assets/icons/download.svg" type="image/svg+xml"></object>
                    Download {{dataOpt?.fileType?.toUpperCase()}}
                </button>
                <div *ngIf="['Buildings', 'Floors', 'Locations'].includes(dataOpt.title)">
                    <venues-select-list
                                        label="Select venues"
                                        [items]="venues"
                                        (selectionChange)="onVenueSelectionChange($event, dataOpt)">
                    </venues-select-list>
                </div>
                <div *ngIf="dataOpt.path === 'graphs'">
                    <mi-dropdown
                                 class="mi-dropdown"
                                 [items]="graphDropdownItems"
                                 [selected]="selectedGraphDropdownItem"
                                 [disabled]="dataOpt.showSpinner"
                                 (change)="onSelectedGraphDropdownItemChanged($event)">
                    </mi-dropdown>
                </div>
            </div>
        </mi-card>
    </div>
</div>