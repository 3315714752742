import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { defaultIfEmpty, filter, flatMap, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { Building, OUTDOOR_BUILDING } from './building.model';
import { DataService } from '../services/data.service';
import { ExtendedLocation } from '../locations/location.service';
import { Floor } from './floor.model';
import { FloorService } from '../services/floor.service';
import { Injectable } from '@angular/core';
import { MapService } from '../services/map.service';
import { NotificationService } from '../services/notification.service';
import { ObservableStore } from '@codewithdan/observable-store';
import { SolutionService } from '../services/solution.service';
import { StoreState } from '../store/store-state';
import { Venue } from '../venues/venue.model';
import { VenueService } from '../venues/venue.service';
import { environment } from '../../environments/environment';
import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';
import { isNullOrUndefined } from '../../utilities/Object';
import { indexOfNearestPolygon } from '../shared/geometry-helper';
import { isPointInPolygon } from '../shared/geometry-helper/geojson';

@Injectable({ providedIn: 'root' })
export class BuildingService extends ObservableStore<StoreState> {
    private api = environment.APIEndpoint;
    private selectedVenue: Venue;
    private currentBuilding = new BehaviorSubject<Building>(null);
    private currentFloor = new BehaviorSubject<Floor>(null);
    private buildingsOnVenue = new BehaviorSubject<Building[]>([]);
    private buildingLabels: google.maps.Marker[] = [];

    constructor(
        private solutionService: SolutionService,
        private floorService: FloorService,
        private dataService: DataService,
        private mapService: MapService,
        private http: HttpClient,
        private notificationService: NotificationService,
        private venueService: VenueService
    ) {
        super({
            stateSliceSelector: state => ({ buildings: state?.buildings })
        });

        this.venueService.selectedVenue$
            .pipe(
                tap(venue => this.selectedVenue = venue),
                switchMap(venue => this.fetchBuildings({ venue: venue.name })))
            .subscribe(buildings => this.buildingsOnVenue.next(buildings));

        this.selectedBuilding$
            .pipe(
                filter(building => !!building),
                withLatestFrom(this.selectedFloor$)
            ).subscribe(([building, selectedFloor]) => {
                this.setCurrentFloor(building?.floors.find(buildingFloor => buildingFloor.floorIndex === selectedFloor?.floorIndex));
            });
    }

    /**
     * Get buildings as an observable.
     *
     * @readonly
     * @type {Observable<Building[]>}
     * @memberof BuildingService
     */
    public get buildings$(): Observable<Building[]> {
        return this.buildingsOnVenue.asObservable();
    }


    /**
     * Observable for the selected building.
     *
     * @readonly
     * @type {Observable<Building>}
     * @memberof BuildingService
     */
    public get selectedBuilding$(): Observable<Building> {
        return this.currentBuilding.asObservable();
    }

    /**
     * Observable for the selected floor.
     *
     * @readonly
     * @type {Observable<Floor>}
     * @memberof BuildingService
     */
    public get selectedFloor$(): Observable<Floor> {
        return this.currentFloor.asObservable();
    }

    /**
     * Get buildings from the store.
     *
     * @returns {Building[]}
     * @memberof BuildingService
     */
    public getBuildingsFromStore(): Building[] {
        const buildings = this.buildingsOnVenue.value;
        buildings?.sort((buildingA, buildingB) => buildingA.displayName.trimStart().toLowerCase().localeCompare(buildingB.displayName.trimStart().toLowerCase()));
        return buildings;
    }

    /**
     * Fetch buildings from the backend given some query parameters.
     * [params.venue](Optional) filter buildings to a venue.
     *
     * @param {{venue?: string}} [params]
     * @returns {Observable<Building[]>}
     * @memberof BuildingService
     */
    private fetchBuildings(params?: { venue?: string }): Observable<Building[]> {
        const options = params ? { params } : {};
        const endpoint = this.getBuildingsEndpoint();
        return this.dataService.getItems<Building>(endpoint, options)
            .pipe(
                flatMap(buildings => {
                    const solution = this.solutionService.getStaticSolution();
                    return this.setExtraBuildingProperties(buildings, solution?.defaultLanguage);
                }),
                map(buildings => { // save in the store
                    //Sorting the buildings by display name.
                    buildings = buildings.sort((a, b) => ('' + a.displayName).localeCompare(b.displayName));
                    return buildings;
                })
            );
    }

    /**
     * Get buildings from the store cache.
     *
     * @param {{venue: string}} params
     * @returns {Observable<Building[]>}
     * @memberof BuildingService
     */
    public getBuildings(params: { venue: string }): Observable<Building[]> {
        const buildings = this.getStateProperty<Building[]>('buildings');
        // pull from store cache
        return buildings?.length > 0 && buildings[0]?.pathData.venue.toLowerCase() === params.venue.toLowerCase()
            ? of(buildings) : this.fetchBuildings(params);
    }

    /**
     * Retrieve a specific building.
     *
     * @param {string} buildingId
     * @returns {Observable<Building>}
     * @memberof BuildingService
     */
    public getBuilding(buildingId: string): Observable<Building> {
        const solution = this.solutionService.getStaticSolution();
        return this.http.get<Building>(`${this.api}${solution.id}/api/buildings/details/${buildingId}`)
            .pipe(
                flatMap(building => this.setExtraBuildingProperties([building], solution.defaultLanguage)),
                filter(buildings => buildings.length === 1),
                map(buildings => buildings[0])
            );
    }

    /**
     * Returns the building connected with the provided location.
     *
     * @param {ExtendedLocation} locationData
     * @returns {Building}
     */
    public getBuildingByAdministrativeId(locationData: ExtendedLocation, buildings: Building[]): Building {
        const administrativeId = locationData.pathData?.building;
        const building = buildings.find(building => building.administrativeId.toLowerCase() === administrativeId?.toLowerCase());

        return building;
    }

    /**
     * Api endpoint to create a building.
     *
     * @param {Building} building
     * @returns {Observable<any>}
     * @memberof BuildingService
     */
    public createBuilding(building: Building): Observable<any> {
        const solution = this.solutionService.getStaticSolution();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(`${this.api}${solution.id}/api/buildings/`, building, { headers });
    }

    /**
     * Update a building.
     *
     * @param {Building} building
     * @returns {Observable<any>}
     * @memberof BuildingService
     */
    public updateBuilding(building: Building): Observable<any> {
        const endpoint = this.getBuildingsEndpoint();
        return this.dataService.updateItem<Building>(endpoint, building)
            .pipe(
                flatMap(() => {
                    // Update the building's floors
                    const observables = building.floors.map(floor => this.floorService.updateFloor(floor));
                    return forkJoin(observables);
                }),
                defaultIfEmpty(), // calls next if forkJoin does not emit a value when observables is an empty array.
                tap(() => {
                    this.updateBuildingInStore(building);
                    // Emit the current building if it has been updated
                    if (this.currentBuilding.value && this.currentBuilding.value.id === building.id) {
                        this.currentBuilding.next(building);
                    }
                })
            );
    }

    /**
     * Update building in store cache.
     *
     * @private
     * @param {Building} building
     * @memberof BuildingService
     */
    private updateBuildingInStore(building: Building): void {
        const buildings = this.getStateProperty<Building[]>('buildings');
        const index = buildings?.findIndex(_building => _building.id === building.id) || -1;
        if (index > -1) {
            buildings[index] = building;
            this.setState({ buildings }, BuildingStoreActions.EditBuilding);
        }
    }

    /**
     * Get the current building's value or observable.
     * As an observable, publish the building only when the id has changed.
     *
     * @param {boolean} [asValue]
     * @returns {(Building | Observable<Building>)}
     * @memberof BuildingService
     */
    public getCurrentBuilding(asValue?: boolean): Building | Observable<Building> {
        return asValue
            ? this.currentBuilding.getValue()
            : this.currentBuilding.asObservable().pipe(filter(building => !!building));
    }

    /**
     * Set the current building.
     *
     * @param {Building} building
     * @memberof BuildingService
     */
    public setCurrentBuilding(building: Building): void {
        // Short circuit to avoid setting the Building when it hasn't changed.
        if (this.currentBuilding.value === building) return;

        this.currentBuilding.next(building);
    }

    /**
     * Get the current floor's value or observable.
     *
     * @param {boolean} [asValue]
     * @returns {(Floor | Observable<Floor>)}
     * @memberof BuildingService
     */
    public getCurrentFloor(asValue?: boolean): Floor | Observable<Floor> {
        return asValue ? this.currentFloor.getValue() : this.currentFloor.asObservable().pipe(filter(floor => !!floor));
    }

    /**
     * Set the current floor observable.
     *
     * @param {Floor} floor
     * @memberof BuildingService
     * @returns {void}
     */
    public setCurrentFloor(floor: Floor): void {
        const currentBuilding: Building = this.getCurrentBuilding(true) as Building;
        if (!currentBuilding || currentBuilding === OUTDOOR_BUILDING || (floor && this.currentFloor.value === floor))
            return;

        //Don't change the floor if the floor indexes and solution ids are identical.
        if (floor
            && !floor.geometry
            && this.currentFloor?.value?.floorIndex === floor?.floorIndex
            && this.currentFloor?.value?.solutionId === floor?.solutionId) {
            return;
        }

        if (currentBuilding.floors?.length === 0)
            return this.notificationService.showError(`${currentBuilding.displayName} has no floors.`);

        if (!floor) {
            const nextFloor = currentBuilding.floors.find(floor => floor.floorIndex === Number(currentBuilding.defaultFloor))
                ?? currentBuilding.floors.find(floor => floor.floorIndex === Number(this.selectedVenue.defaultFloor))
                ?? currentBuilding.floors[0] ?? null;
            return this.currentFloor.next(nextFloor);
        } else if (floor?.id === this.currentFloor?.value?.id) {
            // If the floor is the same as the current floor, do nothing.
            return;
        } else if (currentBuilding.floors.some(buildingFloor => buildingFloor.id === floor.id)) {
            return this.currentFloor.next(floor);
        } else if (Number.isInteger(currentBuilding.defaultFloor)) {
            return this.currentFloor.next(currentBuilding.floors.find(floor => floor.floorIndex === currentBuilding.defaultFloor));
        } else {
            const nextFloor = currentBuilding?.floors.reduce((prev, curr) => {
                return Math.abs(floor.floorIndex - curr.floorIndex) <= Math.abs(floor.floorIndex - prev.floorIndex) ? curr : prev;
            });
            return this.currentFloor.next(nextFloor);
        }
    }

    /**
     * Gets the initial/default floor that will be shown when switching venues or buildings.
     * Checks if current floor's index is part of the current building.
     *
     * @param {Venue} venue
     * @returns {Floor}
     * @memberof BuildingService
     */
    public getInitialFloor(venue: Venue): Floor {
        let floor: Floor = { floorIndex: 0, geometry: null, pathData: null, floorInfo: null, solutionId: null, id: null };
        if (venue) {
            const building = this.currentBuilding.getValue();
            const currentFloor = this.currentFloor.getValue() || {
                floorIndex: building?.defaultFloor || +venue.defaultFloor,
                geometry: building?.geometry || null,
                pathData: null,
                floorInfo: null,
                solutionId: null,
                id: null
            };

            const buildingFloor = building?.floors?.find(_floor => _floor.floorIndex === currentFloor?.floorIndex);
            floor = buildingFloor ? buildingFloor : currentFloor;
        }

        return floor;
    }

    /**
     * Get buildings default floor.
     *
     * @param {Venue} venue
     * @param {Building} building
     * @returns {Floor}
     * @memberof BuildingService
     */
    public getDefaultFloor(venue: Venue, building: Building): Floor {
        const defaultFloorGeometry = building.floors.find(floor => floor.floorIndex === building.defaultFloor)?.geometry;
        return {
            floorIndex: building.defaultFloor || +venue.defaultFloor,
            geometry: defaultFloorGeometry || building.geometry || null,
            pathData: null,
            floorInfo: null,
            solutionId: null,
            id: null,
        };
    }

    /**
     * Set the displayName property of each building according to the language.
     * Map the floors to each building object.
     *
     * @private
     * @param {Building[]} buildings
     * @param {string} language
     * @returns {Observable<Building[]>}
     * @memberof BuildingService
     */
    private setExtraBuildingProperties(buildings: Building[], language: string): Observable<Building[]> {
        return this.floorService.getFloors()
            .pipe(
                map(floors => {
                    return buildings.map(building => {
                        const info = building.buildingInfo.find(translation => translation.language === language);
                        building.displayName = info ? info.name : 'n/a';
                        building.floors = floors.filter(floor => building.administrativeId.toLowerCase() === floor.pathData.building.toLowerCase());
                        return building;
                    });
                })
            );
    }

    /**
     * Get buildings endpoint.
     *
     * @private
     * @returns {string}
     * @memberof BuildingService
     */
    private getBuildingsEndpoint(): string {
        const solution = this.solutionService.getStaticSolution();
        return `${solution?.id}/api/buildings`;
    }

    /**
     * Get building nearest to the target coordinate.
     *
     * @param {Building[] = []} buildings
     * @param {GeoJSON.Point} point - Target coordinate.
     * @returns {Building}
     * @memberof BuildingService
     */
    public getNearestBuilding(buildings: Building[] = [], point: GeoJSON.Point): Building {
        if (buildings.length <= 0 || !point) {
            return;
        }

        const currentBuilding = this.currentBuilding.value;
        const currentFloor = this.currentFloor.value;

        // When map middle point intersects with building geometry, show this buidling on the current floor.
        if (currentBuilding?.geometry && isPointInPolygon(point.coordinates, currentBuilding.geometry as GeoJSON.Polygon | GeoJSON.MultiPolygon) && currentBuilding.floors.includes(currentFloor)) {
            return currentBuilding;
        }

        // If buildings are changing, and map middle point intersects with more than one building outline, choose the one that has same floor.
        const building = buildings
            .find(building => building.floors
                .some(floor => floor?.floorIndex === currentFloor?.floorIndex && isPointInPolygon(point?.coordinates, floor?.geometry as GeoJSON.Polygon | GeoJSON.MultiPolygon))
            );

        if (building) {
            return building;
        }

        // Returns array of floors based on current buildings.
        const floorToBuildingMap = buildings.reduce((floorToBuildingMap, building) => {
            building.floors?.forEach(floor => {
                floorToBuildingMap.push([floor, building]);
            });
            return floorToBuildingMap;

        }, []);

        // Finds the floor that corresponds to closest polygon to the point.
        const indexOfNearestBuilding = indexOfNearestPolygon(floorToBuildingMap.flatMap(([floor]) => floor.geometry), point);

        return floorToBuildingMap[indexOfNearestBuilding]?.[1];
    }


    /**
     * Returns an observable for observing the building in focus.
     *
     * @param {GeoJSON.Point} center
     * @returns {Observable<Building>}
     */
    getNearestBuildingAsObservable(center: GeoJSON.Point): Observable<Building> {
        return this.buildings$.pipe(map(buildings => this.getNearestBuilding(buildings, center)), take(1));
    }

    /**
     * Draw the given buildings label.
     *
     * @param {Building[]} buildings
     * @memberof BuildingService
     */
    public drawBuildingLabels(buildings: Building[]): void {
        const map = this.mapService.getMap();
        const visible = map?.getZoom() > 14 && map?.getZoom() < 18;
        // remove all labels
        this.buildingLabels.forEach(label => this.mapService.removeFromMap(label));
        this.buildingLabels = [];

        for (const building of buildings) {
            const label: google.maps.MarkerLabel = {
                color: midt['tailwind-colors'].black.value,
                fontSize: '14px',
                fontWeight: 'bold',
                text: building.displayName
            };

            const icon: google.maps.Symbol = {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 0 // so the marker image doesn't show
            };

            const labelMarker = new google.maps.Marker({
                map,
                icon,
                label,
                visible,
                clickable: false,
            });

            labelMarker.setPosition({ lat: building.anchor.coordinates[1], lng: building.anchor.coordinates[0] });
            this.buildingLabels.push(labelMarker);
        }
    }

    /**
     * Show/hide buildings label visibility in relation to the zoom level.
     *
     * @memberof BuildingService
     */
    public setBuildingLabelsVisibility(): void {
        const visible = this.mapService.getZoom() > 14 && this.mapService.getZoom() < 18;
        this.buildingLabels.forEach(label => label.setVisible(visible));
    }

    /**
     * Set the building for a given location.
     *
     * @param {ExtendedLocation} location
     */
    public setBuildingBasedOnLocation(location: ExtendedLocation): void {
        if (this.buildingsOnVenue?.value?.length === 0) return;

        const building = this.buildingsOnVenue.value.find(building => building.administrativeId === location?.pathData?.building);
        if (building) {
            this.setCurrentBuilding(building);
        }
    }

    /**
     * Returns the floor where the given geometry is located..
     *
     * @param {GeoJSON.Point} geometry
     * @returns {any}
     */
    public getFloorByGeometry(geometry: GeoJSON.Point): Floor {
        const currentFloorIndex = this.currentFloor?.value?.floorIndex;

        if (isNullOrUndefined(currentFloorIndex)) {
            return;
        }

        for (const building of this.buildingsOnVenue.value) {
            for (const floor of building.floors) {
                if (floor.floorIndex === currentFloorIndex && isPointInPolygon(geometry.coordinates, floor.geometry as GeoJSON.Polygon | GeoJSON.MultiPolygon)) {
                    return floor;
                }
            }
        }

        return {
            floorIndex: this.currentFloor?.value?.floorIndex,
            displayName: this.currentFloor?.value?.displayName,
            pathData: null,
            floorInfo: null,
            geometry: null,
            solutionId: null,
            id: null
        };
    }
}

export enum BuildingStoreActions {
    AddBuilding = 'ADD_BUILDING',
    GetBuilding = 'GET_BUILDING',
    EditBuilding = 'EDIT_BUILDING',
    RemoveBuilding = 'REMOVE_BUILDING',
    GetBuildings = 'GET_BUILDINGS',
    UpdateCurrentBuilding = 'UPDATE_CURRENT_BUILDING',
}
