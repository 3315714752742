<form [formGroup]="occupantForm" class="form">
    <navigatable-container>
        <navigatable-section header="Occupant">
            <section class="section-container">
                <!-- Occupant Template -->
                <occupant-template-dropdown class="setting" formControlName="occupantTemplateId"></occupant-template-dropdown>

                <!-- Translations -->
                <navigatable-section class="section" header="Translations">
                    <translations-form
                        formControlName="translations"
                        [isLockable]="true"
                        [isNameLocked]="isNameLocked"
                        [isDescriptionLocked]="isDescriptionLocked"
                        (nameLocked)="toggleNameInheritance($event)"
                        (descriptionLocked)="toggleDescriptionInheritance($event)"
                        class="setting"></translations-form>
                </navigatable-section>

                <!-- Aliases -->
                <occupant-aliases-list class="setting" formControlName="aliases"
                    [nonDeletableAliases]="nonDeletableAliases" [futureAliases]="futureAliases"></occupant-aliases-list>

                <label class="mi-label">
                    <input class="mi-toggle" type="checkbox" formControlName="isAnchor" />
                    Anchor
                    <info-tooltip class="tooltip" text="An Anchor Occupant is a Location of extra importance. E.g. in a shopping mall it could be a large store with a broad appeal that attracts customers."></info-tooltip>
                </label>

                <label class="mi-label">
                    <input class="mi-toggle" type="checkbox" formControlName="isOperating" />
                    Operational
                    <info-tooltip class="tooltip" text="Operational can be used to indicate if an Occupant is temporarily closed. Setting an Occupant as “not Operational” could e.g. be useful if construction/maintenance is being done. Occupants will be Operational per default."></info-tooltip>
                </label>

                <!-- Correlation ID -->
                <correlation-id-input class="setting" formControlName="correlationId" [location]="location" [occupantCorrelationId]="occupant.correlationId"></correlation-id-input>
            </section>
        </navigatable-section>

        <!-- Logo -->
        <navigatable-section class="section" header="Logo" [isLockable]="true"
            [locked]="getFormControl(occupantForm, 'logoURI').disabled" (lockedChange)="toggleInheritance('logoURI', $event)">
            <image-upload [isLocked]="getFormControl(occupantForm, 'logoURI').disabled"
                [imageSrc]="occupantLogo"
                (imageUrlChanged)="onLogoUriChange($event)"></image-upload>
        </navigatable-section>

        <!-- Opening Hours -->
        <navigatable-section formGroupName="businessHours" class="section" header="Opening Hours"
            [isLockable]="true" [locked]="getFormControl(occupantForm, 'businessHours.standardOpeningHours').disabled"
            (lockedChange)="toggleInheritance('businessHours.standardOpeningHours', $event)">
            <standard-opening-hours-form formControlName="standardOpeningHours"></standard-opening-hours-form>
        </navigatable-section>

        <!-- Address -->
        <navigatable-section class="section" header="Address" [isLockable]="true"
            [locked]="getFormControl(occupantForm, 'address').disabled" (lockedChange)="toggleInheritance('address', $event)">
            <address-form formControlName="address"></address-form>
        </navigatable-section>

        <!-- Contact -->
        <navigatable-section class="section" header="Contact" [isLockable]="true"
            [locked]="getFormControl(occupantForm, 'contactInformation').disabled"
            (lockedChange)="toggleInheritance('contactInformation', $event)">
            <contact-form formControlName="contactInformation"></contact-form>
        </navigatable-section>

        <navigatable-section class="section" header="Details">
            <dl class="description-list">
                <dt class="mi-label description-list__term">MapsIndoors Occupant ID:</dt>
                <dd class="description-list__detail"><code>{{ occupant.id }}</code></dd>
                
                <dt class="mi-label description-list__term">MapsIndoors Occupant Template ID:</dt>
                <dd class="description-list__detail"><code>{{ occupantTemplate.id }}</code></dd>
                
                <dt class="mi-label description-list__term">MapsIndoors Occupant Category ID:</dt>
                <dd class="description-list__detail"><code>{{ occupantTemplate.occupantCategoryId }}</code></dd>
                </dl>
        </navigatable-section>
    </navigatable-container>

    <panel-footer *ngIf="isOccupantFormDirty" class="footer" text="You have unsaved changes.">
        <button type="button" class="mi-button mi-button--link" (click)="onDiscard()">Discard</button>
        <button type="submit" class="mi-button mi-button--primary" (click)="onSave()" *ngIf="occupant.id" [disabled]="!occupantForm.valid">Save</button>
        <button type="submit" class="mi-button mi-button--primary" (click)="onSaveAndClose()" [disabled]="!occupantForm.valid">Save and close</button>
    </panel-footer>
</form>