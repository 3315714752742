import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';

import { Solution } from '../../../solutions/solution.model';
import { SolutionService } from '../../../services/solution.service';
import { Translation } from '../../interfaces/translation.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { CategoryService } from '../../../categories/category.service';
import { Observable, forkJoin } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { NotificationService } from '../../../services/notification.service';
import { isTextEmpty } from '../../../shared/validators/validators';

@Component({
    selector: 'translations-form',
    templateUrl: './translations-form.component.html',
    styleUrls: ['./translations-form.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TranslationsFormComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => TranslationsFormComponent),
        multi: true,
    }]
})
export class TranslationsFormComponent implements ControlValueAccessor, Validator, OnInit {

    @Input() allTranslationsAreRequired: boolean = true;

    @Input() isLockable: boolean;

    /**
     * Set the locked state of the name field.
     */
    @Input() set isNameLocked(isNameLocked: boolean) {
        this.isNameLockedDown = isNameLocked;
        this.setDisabledForm();
    }

    /**
     * Set the locked state of the description field.
     */
    @Input() set isDescriptionLocked(isDescriptionLocked: boolean) {
        this.isDescriptionLockedDown = isDescriptionLocked;
        this.setDisabledForm();
    }

    public isNameLockedDown: boolean = false;
    public isDescriptionLockedDown: boolean = false;

    public selectedSolution: Solution;
    public translations: Translation[] = [];
    public languages: Set<string>;

    public onChange: (value: Translation[]) => void = () => { };
    public onTouch: () => void = () => { };
    public isDisabled: boolean = false;

    public translationsForm: FormGroup = new FormGroup({});

    @Output()
    public nameLocked: EventEmitter<boolean> = new EventEmitter();

    @Output()
    public descriptionLocked: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private categoryService: CategoryService,
        private formBuilder: FormBuilder,
        private notificationService: NotificationService,
        private solutionService: SolutionService,
        private spinner: NgxSpinnerService
    ) {
        this.solutionService.selectedSolution$.subscribe(solution => this.selectedSolution = solution);
    }

    /**
     * Angular lifecycle hook.
     */
    ngOnInit(): void {
        this.translationsForm.valueChanges.subscribe((formValues) => {
            const translations = this.translations.map((translation) => ({ ...translation, ...formValues[translation.language] }));
            this.onChange(translations);
        });
    }

    /**
     * Create translation's form-group.
     *
     * @private
     * @param {Translation[]} translations
     * @returns {FormGroup}
     * @memberof LocationBasicInfoComponent
     */
    private createTranslationsForm(translations: Translation[]): FormGroup {
        return translations.reduce((formGroup, translation) => {
            if (translation.language === 'generic')
                return formGroup;

            const required = translation.language === this.selectedSolution.defaultLanguage || this.allTranslationsAreRequired ? Validators.required : null;
            const group = this.formBuilder.group({
                name: [translation.name, [required, isTextEmpty()]],
                description: [translation.description || '']
            });
            formGroup.addControl(translation.language, group);
            return formGroup;
        }, this.translationsForm);
    }

    /**
     * Remove all form-groups from the form.
     *
     * @param {FormGroup} formGroup
     */
    private removeFormGroups(formGroup: FormGroup): void {
        Object.keys(formGroup.controls).forEach((control) => {
            formGroup.removeControl(control);
        });
    }

    /**
     * Enable or disable the form depending on the locked state.
     */
    private setDisabledForm(): void {
        for (const field in this.translationsForm.controls) {
            const translationsGroup = this.translationsForm.get(field);

            if (this.isNameLockedDown) {
                translationsGroup.get('name').disable({ emitEvent: false });
            } else if (!this.isNameLockedDown) {
                translationsGroup.get('name').enable({ emitEvent: false });
            }

            if (this.isDescriptionLockedDown) {
                translationsGroup.get('description').disable({ emitEvent: false });
            } else if (!this.isDescriptionLockedDown) {
                translationsGroup.get('description').enable({ emitEvent: false });
            }
        }
    }

    /**
     * Toggles the locked state of the name field.
     */
    public onNameToggleChange(): void {
        this.isNameLockedDown = !this.isNameLockedDown;
        this.nameLocked.emit(this.isNameLockedDown);
    }

    /**
     * Toggles the locked state of the description field.
     */
    public onDescriptionToggleChange(): void {
        this.isDescriptionLockedDown = !this.isDescriptionLockedDown;
        this.descriptionLocked.emit(this.isDescriptionLockedDown);
    }

    /**
     * Prevents the default behavior of the event and stops the event from
     * propagating up the DOM tree.
     *
     * @param {MouseEvent} e
     */
    public preventDefaultAndStopPropagation(e: MouseEvent): void {
        e.stopPropagation();
        e.preventDefault();
    }

    /**
     * Creates an observable that translates the given field.
     *
     * @param {string} field
     * @param {FormControl} languageFromControl
     * @param {string} lng
     * @param {string} language
     * @returns {Observable<unknown>}
     */
    private createTranslateObservable(field: string, languageFromControl: FormControl, lng: string, language: string): Observable<unknown> {
        return this.categoryService.googleTranslate(languageFromControl[field], lng, language).pipe(
            map((translatedValue) => {
                translatedValue.field = field;
                return translatedValue;
            })
        );
    }

    /**
     * Translates the translations based on the provided language.
     *
     * @param {string} language
     */
    public onTranslate(language: string): void {
        const googleTranslateObservables = [];
        this.spinner.show();

        for (const lng of this.languages) {
            if (lng !== language) {
                const languageFromControl = this.translationsForm.controls[language].value;

                googleTranslateObservables.push(this.createTranslateObservable('name', languageFromControl, lng, language));
                googleTranslateObservables.push(this.createTranslateObservable('description', languageFromControl, lng, language));
            }
        }

        forkJoin(googleTranslateObservables)
            .pipe(
                finalize(() => this.spinner.hide())
            )
            .subscribe(lng => {
                lng.forEach((value: any) => {
                    const languageFormControl = this.translationsForm.controls[value.language];
                    languageFormControl.setValue({
                        ...languageFormControl.value,
                        [value.field]: value.translations[0].translatedText
                    });
                });
                this.notificationService.showSuccess('Successfully translated languages');
            }, () => {
                this.notificationService.showError('Could not translate. Please try again.');
            });
    }

    /**
     * Writes a new value to the element.
     *
     * This method is called by the forms API to write to the view when programmatic
     * changes from model to view are requested.
     *
     * @param {Translation[]} translations - The new value for the element.
     */
    writeValue(translations: Translation[]): void {
        if (Array.isArray(translations)) {
            this.languages = new Set(translations.map(translation => translation.language));
            this.languages.delete('generic');
            this.removeFormGroups(this.translationsForm);
            this.translationsForm = this.createTranslationsForm(translations);
            this.translations = translations;
            this.setDisabledForm();
        }
    }

    /**
     * Registers a callback function that is called when the control's value
     * changes in the UI.
     *
     * This method is called by the forms API on initialization to update the form
     * model when values propagate from the view to the model.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnChange(fn: (value: Translation[]) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that is called by the forms API on initialization
     * to update the form model on blur.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    /**
     * Function that is called by the forms API when the control status changes to
     * or from 'DISABLED'. Depending on the status, it enables or disables the
     * appropriate DOM element.
     *
     * @param {boolean} isDisabled - The disabled status to set on the element.
     */
    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
    /**
     * Method that performs synchronous validation against the provided control.
     *
     * @returns {ValidationErrors}
     */
    validate(): ValidationErrors {
        return this.translationsForm.invalid ? { invalid: true } : null;
    }
}