import { OpeningHours } from '../../services/OccupantServices/occupant.model';

export class StandardOpeningHours {
    // TODO: change this to closedAllDay: boolean = false; when BE is done. Right same start+end time are not accepted by BE but will be changed.
    monday: OpeningHours = {
        closedAllDay: true,
        startTime: null,
        endTime: null
    };
    tuesday: OpeningHours = {
        closedAllDay: true,
        startTime: null,
        endTime: null
    };
    wednesday: OpeningHours = {
        closedAllDay: true,
        startTime: null,
        endTime: null
    };
    thursday: OpeningHours = {
        closedAllDay: true,
        startTime: null,
        endTime: null
    };
    friday: OpeningHours = {
        closedAllDay: true,
        startTime: null,
        endTime: null
    };
    saturday: OpeningHours = {
        closedAllDay: true,
        startTime: null,
        endTime: null
    };
    sunday: OpeningHours = {
        closedAllDay: true,
        startTime: null,
        endTime: null
    };

    constructor(standardOpeningHours?: StandardOpeningHours) {
        Object.assign(this, standardOpeningHours);
    }

    /**
     * Creates a StandardOpeningHours object. Returns null if every property is empty.
     *
     * @param {StandardOpeningHours} standardOpeningHours
     * @returns {StandardOpeningHours}
     */
    static createStandardOpeningHours(standardOpeningHours?: StandardOpeningHours): StandardOpeningHours {
        if (Object.values(standardOpeningHours ?? {}).every((value) => value === null || value === '')) {
            return null;
        } else {
            return new StandardOpeningHours(standardOpeningHours);
        }
    }

}