import { ErrorHandler, Injectable } from '@angular/core';
import { SentryErrorHandler } from '@sentry/angular';
import { environment } from '../../environments/environment';

@Injectable()
export class GlobalErrorHandler extends SentryErrorHandler implements ErrorHandler {

    // Error handling is important and needs to be loaded first.
    // Because of this we should manually inject the services with Injector.
    constructor() {
        super({ logErrors: environment?.sendLogsToSentry ?? false });
    }

    handleError(error): void {
        super.handleError(error);
        // !IMPORTANT: Throw error to stop change detection from running again and creating an infinite loop.
        // This happens for errors in html templates or in ngOnChange licycle hook.
        if (error instanceof TypeError) {
            throw error;
        }
    }
}
