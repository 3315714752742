import { Component, ComponentRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Optional, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ExtendedLocation, LocationService } from '../../locations/location.service';
import { DisplayRule, Location, LocationType, Geometry, Anchor } from '../../locations/location.model';
import { MapSidebar } from '../map-sidebar/map-sidebar.interface';
import { MapUIComponents, MapUIService } from '../map-ui-service/map-ui.service';
import { Occupant } from '../../services/OccupantServices/Occupant';
import { OccupantService } from '../../services/OccupantServices/occupant.service';
import { OccupantTemplate } from '../../services/OccupantServices/occupantTemplate.model';
import { OccupantTemplateService } from '../../services/OccupantServices/occupant-template.service';
import { Solution } from '../../solutions/solution.model';
import { SolutionService } from '../../services/solution.service';
import { catchError, filter, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { mergeObjects, primitiveClone, setNestedProperty } from '../../shared/object-helper';
import { PanelTab, Tab } from '../../shared/panel-header-tab/panel-header-tab.component';
import { LocationDetailsEditorComponent, LocationFormField } from './location-details-editor/location-details-editor.component';
import { OccupantDetailsEditorComponent } from './occupant-details-editor/occupant-details-editor.component';
import { MapToolbarService } from '../map-toolbar/map-toolbar.service';
import { LocationDetailsToolbarComponent } from '../map-toolbar/tools/location-details/location-details-toolbar.component';
import { EmptyToolbarComponent } from '../map-toolbar/tools/empty/empty-toolbar.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../services/notification.service';
import { MapSidebarService } from '../map-sidebar/map-sidebar.service';
import { DisplayRuleDetailsComponent } from '../../display-rule-details/display-rule-details.component';
import { LocationEditorOperation } from '../../GeodataEditor/GeodataEditorOperation/LocationEditorOperation';
import { GeodataEditor, GeodataEditorFactory } from '../../GeodataEditor/GeodataEditor.factory';
import { BaseMapAdapter } from '../../MapAdapter/BaseMapAdapter';
import { MapAdapterMediator } from '../map-adapter.mediator';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { merge } from '../../../utilities/Object';
import { NetworkService } from '../../network-access/network.service';
import { LocationType as TypeOfLocation } from '../../location-types/location-type.model';
import { FloorService } from '../../services/floor.service';
import { Router } from '@angular/router';
import { stayAtCurrentUrl } from '../../solution-settings/solution-settings-shared-functions.component';
import { TypesService } from '../../services/types.service';

@Component({
    selector: 'editor-container',
    templateUrl: './editor-container.component.html',
    styleUrls: ['./editor-container.component.scss'],
})
export class EditorContainerComponent implements OnInit, OnDestroy, MapSidebar {
    @ViewChild('container', { read: ViewContainerRef, static: true }) private container!: ViewContainerRef;

    /**
     * Setter for setting the location to be edited.
     */
    @Input()
    set data(input: Location) {
        this._location = primitiveClone(input) as ExtendedLocation;
        this._locationType = this._location.typeOfLocation;
        this._isLocationObstacle = input.locationSettings?.obstacle ?? false;
        this._currentLocationGeometry = {
            geometry: this._location.geometry,
            anchor: this._location.anchor
        };

        this.makeLocationDraggable(this._location);
    }

    @Output()
    public closed: EventEmitter<void> = new EventEmitter();

    public tabs = [
        { isActive: false, isDisabled: false, isToggleChecked: false, title: 'Occupant Editor', id: Tab.OccupantDetails, hasToggle: true },
        { isActive: true, isDisabled: false, title: 'Location Editor', id: Tab.LocationDetails, hasToggle: false },
    ];

    private _activeTab: PanelTab = this.findTabById(Tab.LocationDetails);
    private _currentComponent: ComponentRef<LocationDetailsEditorComponent | OccupantDetailsEditorComponent>;
    private _currentLocationGeometry: { geometry: Geometry, anchor: Anchor };
    private _currentSolution: Solution;
    private _duplicationInProgress: boolean = false;
    private _editLocationOperation: LocationEditorOperation;
    private _editorSubscription: Subscription = new Subscription();
    private _geoDataEditor: GeodataEditor;
    private _isCurrentEditorFormDirty: boolean = false;
    private _isLocationObstacle: boolean = false;
    private _isOccupantModuleEnabled: boolean = false;
    private _location: ExtendedLocation;
    private _locationHasOccupant: boolean = false;
    private _locationType: TypeOfLocation;
    private _mapAdapter: BaseMapAdapter;
    private _occupantData: { occupant: Occupant, occupantTemplate: OccupantTemplate };
    private _toolbarRef: ComponentRef<LocationDetailsToolbarComponent | EmptyToolbarComponent>;
    private locationGeometrySubject$: Subject<{ geometry: Geometry, anchor: Anchor }> = new Subject();

    /**
     * Active tab getter.
     *
     * @readonly
     * @type {PanelTab}
     */
    public get activeTab(): PanelTab {
        return this._activeTab;
    }

    /**
     * Occupant getter.
     *
     * @readonly
     * @type {boolean}
     */
    public get isOccupantModuleEnabled(): boolean {
        return this._isOccupantModuleEnabled;
    }

    /**
     * Location getter.
     *
     * @readonly
     * @type {ExtendedLocation}
     */
    public get location(): ExtendedLocation {
        return this._location;
    }

    /**
     * Property that reflects if the location can be duplicated.
     *
     * @readonly
     * @type {boolean}
     */
    public get canLocationBeDuplicated(): boolean {
        return (this?._location?.id > '' && this?._location?.locationType !== LocationType.Room) && !this._duplicationInProgress;
    }

    /**
     * Property that reflects if the location can be deleted.
     *
     * @readonly
     * @type {boolean}
     */
    public get canLocationBeDeleted(): boolean {
        return (this?._location?.id > '' && this?._location?.locationType !== LocationType.Room);
    }

    /**
     * Deletes the current location.
     *
     * @returns {Observable<void>}
     */
    private get deleteLocation$(): Observable<void> {
        return this.locationService.deleteLocation(this.location)
            .pipe(
                tap(() => {
                    if (this.location.locationSettings?.obstacle) {
                        this.networkService.updateRouteElements();
                    }
                }),
                first());
    }

    /**
     * Deletes the current occupant.
     *
     * @returns {Observable<void>}
     */
    private get deleteOccupant$(): Observable<void> {
        return this.occupantService.deleteOccupant(this._occupantData.occupant)
            .pipe(first());
    }

    constructor(
        @Optional() private mapAdapterMediator: MapAdapterMediator,
        private displayRuleService: DisplayRuleService,
        private locationService: LocationService,
        private mapSidebar: MapSidebarService,
        private mapToolbar: MapToolbarService,
        private mapUIService: MapUIService,
        private networkService: NetworkService,
        private notificationService: NotificationService,
        private occupantService: OccupantService,
        private occupantTemplateService: OccupantTemplateService,
        private solutionService: SolutionService,
        private spinner: NgxSpinnerService,
        private floorService: FloorService,
        private router: Router,
        private typesService: TypesService
    ) {
        this._mapAdapter = this.mapAdapterMediator?.getMapAdapter();
        if (this._mapAdapter) {
            this._geoDataEditor = GeodataEditorFactory.create(this._mapAdapter, this.displayRuleService, this.locationService);
        }

        this.solutionService.getCurrentSolution()
            .pipe(
                tap((solution) => {
                    this._currentSolution = solution;
                    this._isOccupantModuleEnabled = Solution.hasModule(solution, 'occupants');
                }),
                switchMap(() => this.occupantTemplateService.simplifiedOccupantTemplates$)
            ).subscribe();
    }

    /**
     * NgOnInit.
     */
    async ngOnInit(): Promise<void> {
        this.floorService.disableFloorSelector(true);

        this.router.events.subscribe(() => {
            if (this._isCurrentEditorFormDirty) {
                // eslint-disable-next-line no-alert
                if (!confirm('You have unsaved changes. Are you sure you want to continue?')) {
                    stayAtCurrentUrl(this.router);
                    return;
                } else {
                    this.close(true);
                }
            }
        });

        const occupant = this.occupantService.getOccupantByLocationId(this.location.id);
        const occupantTemplate = occupant ? await this.occupantTemplateService.getOccupantTemplate(occupant?.occupantTemplateId) : null;
        this._occupantData = { occupant, occupantTemplate };

        this._mapAdapter.panToGeometry(this.location.geometry as GeoJSON.Geometry, { left: 736, right: 66, top: 0, bottom: 0 });

        if (this._isOccupantModuleEnabled && this._occupantData.occupant && this._occupantData.occupantTemplate) {
            this.activateTab(Tab.OccupantDetails);
            this._locationHasOccupant = true;
        } else {
            this.activateTab(Tab.LocationDetails);
        }
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this._editLocationOperation?.complete();
        this._editorSubscription.unsubscribe();
    }

    /**
     * Makes the location on the map draggable.
     *
     * @private
     * @param {ExtendedLocation} location
     */
    private makeLocationDraggable(location: ExtendedLocation): void {
        this._editLocationOperation?.complete();
        this._editLocationOperation = this._geoDataEditor?.editLocation(location as ExtendedLocation);
        this._editLocationOperation.changes.subscribe((e) => {
            this._currentLocationGeometry = e as { geometry: Geometry, anchor: Anchor };
            this.locationGeometrySubject$.next(e as { geometry: Geometry, anchor: Anchor });
        });
    }

    /**
     * Activate a tab in the header and create the active editor component.
     *
     * @param {Tab} id
     */
    private activateTab(id: Tab): void {
        if (this._currentComponent) {
            // eslint-disable-next-line no-alert
            if (this._isCurrentEditorFormDirty && !confirm('You have unsaved changes. Are you sure you want to continue?')) {
                return;
            }

            this._editorSubscription.remove(this.editorCloseSubscription());
            this._editorSubscription.remove(this.editorDirtySubscription());
            this._currentComponent.destroy();
            this._toolbarRef?.destroy();
            this._isCurrentEditorFormDirty = false;
            this.updateDisplayRule();

            if (!this._occupantData?.occupant?.id) {
                this._activeTab.isToggleChecked = false;
            }
        }

        this._activeTab = this.findTabById(id);
        this._activeTab.isActive = true;
        this._activeTab.isToggleChecked = true;
        this._locationType = this.location.typeOfLocation;

        Object.values(this.tabs).forEach((tab) => {
            if (tab.id !== id) {
                tab.isActive = false;
            }
        });

        switch (this._activeTab.id) {
            case Tab.OccupantDetails:
                this.showOccupantDetailsEditor();
                break;
            case Tab.LocationDetails:
            default:
                this.showLocationDetailsEditor();
                break;
        }

        this._editorSubscription
            .add(this.editorDiscardSubscription())
            .add(this.editorCloseSubscription())
            .add(this.editorDirtySubscription())
            .add(this.editorSaveSubscription())
            .add(this._activeTab === this.findTabById(Tab.LocationDetails) ? this.locationFormSubscription() : this.locationTypeSubscription())
            .add(this.createOccupantWithNewLocationSubscription());
    }

    /**
     * Editor close subscription.
     *
     * @returns {Subscription}
     */
    private editorCloseSubscription(): Subscription {
        return this._currentComponent.instance.closed
            .pipe(first())
            .subscribe(() => this.close());
    }

    /**
     * Editor close subscription.
     *
     * @returns {Subscription}
     */
    private editorDirtySubscription(): Subscription {
        return this._currentComponent.instance.dirtyStateChange
            .subscribe((isDirty) => this._isCurrentEditorFormDirty = isDirty);
    }

    /**
     * Editor save subscription.
     *
     * @returns {Subscription}
     */
    private editorSaveSubscription(): Subscription {
        return this._currentComponent.instance.formSavedSubject
            .subscribe(({ location, occupantData }) => {
                if (this._activeTab === this.findTabById(Tab.OccupantDetails) && (this.location.typeOfLocation !== this._locationType)) {
                    const locationWithNewType = this.location;
                    locationWithNewType.type = this._locationType.administrativeId;
                    locationWithNewType.typeOfLocation = this._locationType;
                    this.locationService.updateLocation(locationWithNewType)
                        .subscribe(() => {
                            this._location = locationWithNewType;
                        });
                }

                if (occupantData) {
                    this._occupantData = occupantData;
                }

                if (location) {
                    this._location = location;
                    this.makeLocationDraggable(location);
                }

                this.activateTab(this._activeTab.id);
            });
    }

    /**
     * Editor save subscription.
     *
     * @returns {Subscription}
     */
    private editorDiscardSubscription(): Subscription {
        return this._currentComponent.instance.discardChangesSubject
            .subscribe(() => {
                this._editLocationOperation.reset();
                this._locationType = this.location.typeOfLocation;
                this.updateDisplayRule();
            });
    }

    /**
     * Subscribes to the location's form changes.
     * Updates the location's look based on the location's rotation, type and obstacle status.
     *
     * @returns {Subscription}
     */
    private locationFormSubscription(): Subscription {
        if (this._activeTab === this.findTabById(Tab.LocationDetails)) {
            return (this._currentComponent.instance as LocationDetailsEditorComponent).formChanged
                .subscribe(({ field, value }) => {
                    switch (field) {
                        case LocationFormField.Angle:
                            this._editLocationOperation.rotate(value as number);
                            break;
                        case LocationFormField.Type:
                            this._locationType = value as TypeOfLocation;
                            this.updateDisplayRule();
                            break;
                        case LocationFormField.Obstacle: {
                            this._isLocationObstacle = value as boolean;
                            this._editLocationOperation.showAsObstacle(value as boolean);
                            break;
                        }
                    }
                });
        } else {
            return of(null).subscribe();
        }
    }

    /**
     * Subscribes to the location's type changes coming from the occupant template's changes.
     * Updates the location's look based on the location's type.
     *
     * @returns {Subscription}
     */
    private locationTypeSubscription(): Subscription {
        if (this._activeTab === this.findTabById(Tab.OccupantDetails)) {
            return (this._currentComponent.instance as OccupantDetailsEditorComponent).typeChanged
                .subscribe((type) => {
                    this._locationType = type;
                    this.updateDisplayRule();
                });
        } else {
            return of(null).subscribe();
        }
    }

    /**
     * Takes care of saving a new occupant if there is no location yet.
     *
     * @returns {Subscription}
     */
    private createOccupantWithNewLocationSubscription(): Subscription {
        if (this._activeTab.id === Tab.OccupantDetails) {
            return (this._currentComponent.instance as OccupantDetailsEditorComponent).createOccupantWithNewLocation
                .subscribe((occupant: Occupant) => {
                    this.spinner.show();

                    const typeName = this._locationType.translations.find(t => t.language === this._currentSolution.defaultLanguage).name;
                    const defaultLanguage = this._currentSolution.defaultLanguage;
                    const translation = this._location.translations.find(t => t.language === defaultLanguage);
                    if (translation) {
                        translation.name = typeName;
                    }

                    this._location.type = this._locationType.administrativeId;

                    this.locationService.createLocation(this._location)
                        .pipe(
                            switchMap((location) => {
                                occupant.locationId = location.id;
                                return this.occupantService.createOccupant(occupant);
                            }),
                            tap(() => {
                                this.notificationService.showSuccess('Occupant and Location created successfully!');
                                this.close(true);
                                this.spinner.hide();
                            }),
                            catchError((err: Error) => {
                                this.notificationService.showError(err);
                                this.spinner.hide();
                                return of(null);
                            })
                        )
                        .subscribe();
                });
        } else {
            return of(null).subscribe();
        }
    }

    /**
     * Shows the occupant details editor.
     */
    private showOccupantDetailsEditor(): void {
        this.mapUIService.show(MapUIComponents.OccupantDetailsEditor);
        this._currentComponent = this.container.createComponent(OccupantDetailsEditorComponent);
        this._currentComponent.instance.data = {
            occupantData: this._occupantData,
            location: this._location
        };
        this._currentComponent.instance.mapAdapter = this._mapAdapter;
        this._editLocationOperation.changeEditableState(false);
        this._toolbarRef = this.mapToolbar.show(EmptyToolbarComponent);
    }

    /**
     * Shows the location details editor.
     */
    private showLocationDetailsEditor(): void {
        this.mapUIService.show(MapUIComponents.LocationDetailsEditor);
        this._currentComponent = this.container.createComponent(LocationDetailsEditorComponent);
        const activeComponentInstance = this._currentComponent.instance;
        activeComponentInstance.data = this._location;
        activeComponentInstance.mapAdapter = this._mapAdapter;
        (activeComponentInstance as LocationDetailsEditorComponent).locationGeometry = this.locationGeometrySubject$;
        (activeComponentInstance as LocationDetailsEditorComponent).locationHasOccupant = this._locationHasOccupant;
        this._editLocationOperation.changeEditableState(true);

        this._toolbarRef = this.mapToolbar.show(LocationDetailsToolbarComponent);
        (this._toolbarRef.instance as LocationDetailsToolbarComponent).supportedModes = this._editLocationOperation.modes;
        (this._toolbarRef.instance as LocationDetailsToolbarComponent).setEditorMode(this._editLocationOperation.mode);
        (this._toolbarRef.instance as LocationDetailsToolbarComponent).editorModeChanges.subscribe(mode => this._editLocationOperation.mode = mode);
        (this._toolbarRef.instance as LocationDetailsToolbarComponent).undoEvent.subscribe(() => this._editLocationOperation.undo());
        (this._toolbarRef.instance as LocationDetailsToolbarComponent).redoEvent.subscribe(() => this._editLocationOperation.redo());
    }

    /**
     * Finds the tab by title.
     *
     * @param {Tab} id
     * @returns {PanelTab}
     */
    private findTabById(id: Tab): PanelTab {
        return Object.values(this.tabs).find(tab => tab.id === id);
    }

    /**
     * Confirm location delete (also occupant, if exists).
     *
     * @returns {boolean}
     */
    private confirmLocationDelete(): boolean {
        const message: string = this._occupantData.occupant ? 'Delete this Occupant and Location?' : 'Delete this Location?';
        // eslint-disable-next-line no-alert
        return confirm(message);
    }

    /**
     * Confirm occupant delete.
     *
     * @returns {boolean}
     */
    private confirmOccupantDelete(): boolean {
        // eslint-disable-next-line no-alert
        return confirm('Delete this Occupant?');
    }

    /**
     * Deletes the location and occupant (if present).
     * In order to delete the location, the occupant must be deleted first. Otherwise the occupant would have a reference to a non-existing location.
     *
     * @returns {Observable<void>}
     */
    private deleteData(): Observable<void> {
        if (this._occupantData.occupant) {
            return this.deleteOccupant$
                .pipe(switchMap(() => this.deleteLocation$));
        } else {
            return this.deleteLocation$;
        }
    }

    /**
     * Handles the toggle delete.
     *
     * @param {PanelTab} clickedTab
     */
    private handleOccupantToggleDelete(clickedTab: PanelTab): void {
        if (this.confirmOccupantDelete()) {
            // 1 - Case when the Occupant tab is active, toggle gets toggled off and occupant will be deleted.
            // 2 - Case when the Occupant tab is NOT active, toggle gets toggled off and occupant will be deleted.
            this.spinner.show();
            this.deleteOccupant$.subscribe(() => {
                this.spinner.hide();
                this.notificationService.showSuccess('Occupant deleted.');
                this._locationHasOccupant = false;
                this.activateTab(Tab.LocationDetails);
                clickedTab.isToggleChecked = false;
                this._occupantData.occupant = null;
            }, error => this.notificationService.showError(error));
        } else {
            // 1 - Case when the Occupant tab is active, toggle gets toggled off and occupant will NOT be deleted.
            // 2 - Case when the Occupant tab is NOT active, toggle gets toggled off and occupant will NOT be deleted.
            clickedTab.isToggleChecked = true;
        }
    }

    /**
     * On toggle changed.
     *
     * @param {Tab} id
     */
    public onToggleChanged(id: Tab): void {
        // We only handle toggles on the occupant tab.
        if (id !== Tab.OccupantDetails) {
            return;
        }

        const clickedTab = this.findTabById(id);

        // The tabs need to auto-switch when the toggle gets toggled off.
        if (this._activeTab.id === id) {
            if (this._occupantData?.occupant?.id) {
                // Case when the Occupant tab is active, toggle gets toggled off and the Location tab will be activated. (+ Occupant deleted)
                this.handleOccupantToggleDelete(clickedTab);
            } else {
                // Case when the Occupant tab is active, toggle gets toggled off and the Location tab will be activated. (+ no occupant exists)
                this.activateTab(Tab.LocationDetails);
            }
        } else {
            if (!clickedTab.isToggleChecked) {
                // Case when the Occupant tab is inactive, toggle gets toggled on and the Occupant tab will be activated.
                this.activateTab(id);
            } else {
                // Case when the Occupant tab is inactive, toggle gets toggled off and the Location tab will stay activate. (+ Occupant deleted)
                this.handleOccupantToggleDelete(clickedTab);
            }
        }
    }

    /**
     * On tab clicked.
     *
     * @param {Tab} clickedTab
     */
    public onTabClicked(clickedTab: Tab): void {
        this.activateTab(clickedTab);
    }

    /**
     * Closes the location editor.
     *
     * @returns {boolean}
     */
    public close(skipConfirm: boolean = false): boolean {
        if (skipConfirm || this._currentComponent.instance.confirmDiscard()) {
            this.floorService.disableFloorSelector(false);
            this._toolbarRef?.destroy();
            this._editLocationOperation?.complete();
            this._mapAdapter.viewState.refresh();
            this._isCurrentEditorFormDirty = false;
            this.closed.emit();
            return true;
        } else {
            return false;
        }
    }

    /**
     * Deletes the location (and occupant).
     */
    public onDelete(): void {
        if (this.confirmLocationDelete()) {
            this.spinner.show();
            this.deleteData()
                .pipe(finalize(() => {
                    this.spinner.hide();
                    this.close();
                }))
                .subscribe(() => {
                    const successMessage = this._occupantData.occupant ? 'Occupant and Location deleted successfully.' : 'Location deleted successfully.';
                    this.notificationService.showSuccess(successMessage);
                }, error => this.notificationService.showError(error));
        }
    }

    /**
     * Open the DisplayRuleDetails component in the MapSidebar.
     * Updates the display rule for the location on save.
     *
     * @param {Event} event
     * @param {ExtendedLocation} location
     * @memberof LocationDetailsComponent
     */
    public openDisplayRuleDetails(event: Event, location: ExtendedLocation): void {
        this.activateTab(Tab.LocationDetails);

        // We need to unfocus from the button in order to not open infinite amount of Display Rules Details with space/enter.
        (event.target as HTMLButtonElement).blur();

        if (this._activeTab !== this.findTabById(Tab.LocationDetails) || this._isCurrentEditorFormDirty) {
            return;
        }

        const { geometry, anchor } = this._currentLocationGeometry;
        const { componentInstance } = this.mapSidebar.open(DisplayRuleDetailsComponent, true);

        if (componentInstance) {
            componentInstance.data = location;
            componentInstance.geometries = [(geometry as any), (anchor as any)];
            componentInstance.valueChanges.subscribe(() => {
                const displayRuleDraft = componentInstance.getDisplayRule();
                this.updateDisplayRule(displayRuleDraft);
            });

            const geometryChanges = this._editLocationOperation.changes.subscribe(({ geometry, anchor }) => {
                componentInstance.geometries = [geometry, anchor];
            });

            componentInstance.submit$.pipe(
                filter((displayRule: DisplayRule) => !!displayRule),
                map((displayRule) => {
                    this.spinner.show();
                    const { geometry, anchor } = this._currentLocationGeometry;
                    return { ...this._location, geometry, anchor, displayRule } as ExtendedLocation;
                }),
                switchMap((location) => this.locationService.updateLocation(location)
                    .pipe(
                        map(() => location),
                        finalize(() => this.spinner.hide()))
                )
            ).subscribe((location) => {
                this._location = location;
                this._isLocationObstacle = location.locationSettings?.obstacle ?? false;
                this._currentComponent.instance.data = this._location;
                this.notificationService.showSuccess('Location\'s Display Rule updated successfully.');
                geometryChanges.unsubscribe();
            }, error => this.notificationService.showError(error));

            componentInstance.discard$.subscribe(() => {
                this._editLocationOperation.reset();
                this.updateDisplayRule();
            });

            componentInstance.pushDisplayRuleToType$.subscribe(({ displayRuleProperties, displayRule, onSuccess, onFailure }) => {
                this.spinner.show();

                // We need to update the location type with the new display rule.
                const updatedLocationType = this._location?.typeOfLocation;
                updatedLocationType.displayRule = mergeObjects(updatedLocationType.displayRule, displayRule);

                this.typesService.updateType(updatedLocationType)
                    .pipe(
                        catchError(() => {
                            throw new Error('The location type could not be updated.');
                        }),
                        // We need to update the location with the new display rule - the location will inherit the specified display rules from the location type.
                        switchMap(() => {
                            const location = this._location;
                            displayRuleProperties.forEach(displayRuleProperty => {
                                setNestedProperty(location.displayRule ?? {}, displayRuleProperty, null);
                            });
                            return this.locationService.updateLocation(location).pipe(
                                catchError(() => {
                                    onFailure();
                                    throw new Error('The location could not be updated.');
                                }));
                        }),
                        map(() => location),
                        finalize(() => this.spinner.hide())
                    ).subscribe((location) => {
                        this._location = location;
                        this._location.typeOfLocation = updatedLocationType;
                        this._mapAdapter.viewState.refresh();
                        this.notificationService.showSuccess('Location Type Display rule updated');
                        this.locationService.updateLocationsMapForType(updatedLocationType);
                        onSuccess();
                    }, (err: Error) => {
                        this.notificationService.showError(err.message);
                    });
            });
        }
    }

    /**
     * Redraws the location with the new displayRules while respecting the location's obstacle state.
     *
     * @param {DisplayRule} displayRule
     * @returns {Promise<void>}
     */
    public async updateDisplayRule(displayRule?: DisplayRule): Promise<void> {
        const newDisplayRule = displayRule ?? await this.getCurrentDisplayRule();
        this._editLocationOperation.redraw(newDisplayRule);
    }

    /**
     * Returns the current display rule, if it doesn't exist yet, it creates it based on the location.
     *
     * @returns {Promise<DisplayRule>}
     */
    private async getCurrentDisplayRule(): Promise<DisplayRule> {
        const typeDisplayRule = await this.displayRuleService.getDisplayRule(this._locationType?.id);
        const locationDisplayRule = this.displayRuleService.getDisplayRuleForLocation(this.location.id);
        const displayRule = merge(typeDisplayRule, locationDisplayRule);

        return displayRule;
    }

    /**
     * Creates a location.
     *
     * @param {ExtendedLocation} location
     * @returns {Observable<void>}
     */
    private createLocation(location: ExtendedLocation): Observable<Location> {
        return this.locationService.createLocation(location)
            .pipe(
                tap(() => this.notificationService.showSuccess('Location created successfully!')),
            );
    }

    /**
     * Creates an occupant.
     *
     * @param {Occupant} occupant
     * @returns {Observable<Occupant>}
     */
    private createOccupant(occupant: Occupant): Observable<Occupant> {
        return this.occupantService.createOccupant(occupant)
            .pipe(
                tap(() => this.notificationService.showSuccess('Occupant created successfully!')),
            );
    }

    /**
     * Duplicates the location and the occupant (if exists).
     *
     * @param {KeyboardEvent} [event]
     */
    @HostListener('window:keydown.control.d', ['$event'])
    public onDuplicate(event?: KeyboardEvent): void {
        event?.preventDefault();
        if (this.canLocationBeDuplicated && this._currentComponent.instance.confirmDiscard()) {
            this._duplicationInProgress = true;
            this._editLocationOperation.complete();
            let duplicate = this.locationService.duplicateLocation(this._location);
            duplicate = this.locationService.offsetLocationPosition(duplicate);

            this.spinner.show();
            // First create the copy of the location.
            this.createLocation(duplicate)
                .pipe(
                    switchMap((duplicatedLocation) => {
                        let duplicateOccupant: Occupant;
                        if (this._occupantData?.occupant?.id) {
                            duplicateOccupant = primitiveClone(this._occupantData.occupant);
                            duplicateOccupant.locationId = duplicatedLocation.id;
                        }

                        // If an occupant exists, create copy of it.
                        return (this._occupantData?.occupant?.id ? this.createOccupant(duplicateOccupant) : of(null))
                            .pipe(
                                // We need to return the duplicated location and the duplicated occupant to update the relevant properties later.
                                map((duplicatedOccupant) => ({ duplicatedLocation, duplicatedOccupant }))
                            );
                    }),
                    finalize(() => {
                        // Areas marked as obstacles have an effect on the network, so they need to be updated.
                        if (this._location.locationSettings?.obstacle) {
                            this.networkService.updateRouteElements();
                        }
                        this._duplicationInProgress = false;
                        this.spinner.hide();
                    }))
                .subscribe(duplicates => {
                    this._location = duplicates.duplicatedLocation as ExtendedLocation;
                    this._isLocationObstacle = this._location.locationSettings?.obstacle ?? false;
                    this._occupantData.occupant = duplicates.duplicatedOccupant;
                    this.activateTab(duplicates.duplicatedOccupant ? Tab.OccupantDetails : Tab.LocationDetails);
                    this.makeLocationDraggable(this._location);
                });
        }
    }
}